@import url('https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sevillana&display=swap');


.navbar {
    position: relative;
    width: 100%;
    height: 100px;
    border-bottom: solid 1px #CCC;
    text-align: center;
    background-image: url("../assets/céu.jpg");
    background-position: center;
    background-size: cover;
    box-shadow: 0px 2px 16px 2px rgb(79, 170, 250);
}

.navbar_dark {
    position: relative;
    width: 100%;
    height: 100px;
    border-bottom: solid 1px rgb(30, 74, 113);
    text-align: center;
    color: #DDD;
    background-image: url("../assets/céudark.jpg");
    background-position: center;
    background-size: cover;
    box-shadow: 0px 2px 16px 2px rgb(30, 74, 113);
}

.logo {
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    font-family: "Sevillana", cursive;
    font-size: 55px;
}

.logo_text {
    width: calc(100% - 60px);
    text-shadow: 6px 6px 8px white;
    color: black;
}

.logo_text_dark {
    width: calc(100% - 60px);
    text-shadow: 6px 6px 8px black;
    color: white;
}

.theme_box_light {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 50px;
    height: 25px;
    border-radius: 12px;
    padding: 0 4px;
    border: 2px solid rgb(255, 98, 0);
    background-color: rgba(255, 98, 0, 0.6);
}
.theme_box_light:hover {
    cursor: pointer;
}
.theme_box_light:active {
    justify-content: center;
    border: 2px solid rgb(131, 50, 0);
    background-color: rgba(131, 50, 0, 0.6);
}
.theme_box_dark {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50px;
    height: 25px;
    border-radius: 12px;
    padding: 0 6px;
    border: 2px solid rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
}

.theme_box_dark:hover {
    cursor: pointer;
}

.theme_box_dark:active {
    justify-content: center;
    border: 2px solid rgb(131, 50, 0);
    background-color: rgba(131, 50, 0, 0.6);
}

.theme_box_dark i {
    color: #FFF;
    font-size: 18px;
}

.theme_box_light {
    color: #FFF;
    font-size: 14px;
}
.menu,
.menu_dark,
.menu2,
.menu1 {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
}
.menu_mobil {
    display: none;
    position: absolute;
    left: 10px;
}

.menu_item {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 15px 0 15px;
    text-decoration: none;
    font-family: "Poetsen One", sans-serif;
    color: black;
    font-size: 1.4rem;
    text-shadow: 2px 2px 3px white;
    border-bottom: 2px solid transparent;
}

.menu_item:hover,
.menu_item_dark:hover {
    cursor: pointer;
    transition: border 0.3s;
}

.menu_item_dark {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 15px 0 15px;
    text-decoration: none;
    color: #DDD;
    font-family: "Poetsen One", sans-serif;
    font-size: 1.4rem;
    text-shadow: 2px 2px 3px black;
    border-bottom: 2px solid transparent;
}

.menu_item_active {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 15px 0 15px;
    text-decoration: none;
    color: black;
    font-family: "Poetsen One", sans-serif;
    font-size: 1.4rem;
    text-shadow: 2px 2px 3px white;
    border-bottom: 4px solid whitesmoke;
}

.menu_item_active:hover,
.menu_item_active_dark:hover {
    cursor: default;
}

.menu_item_active_dark {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 15px 0 15px;
    text-decoration: none;
    color: #DDD;
    font-family: "Poetsen One", sans-serif;
    font-size: 1.4rem;
    text-shadow: 2px 2px 3px black;
    border-bottom: 4px solid #CCC;
}

.logo_img {
    height: 100%;
}

.dropbtn {
    padding: 0 10px;
    height: 30px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    font-size: 1.3rem;

}

.dropbtn img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.dropdown {
    position: relative;
    display: inline-block;
    z-index: 2;
}
.dropdown span:hover{
    cursor: pointer;
}
.active{
    border-bottom: 4px solid #CCC;
}
.dropdowni {
    position: relative;
    display: inline-block;
    margin: 0 0 0 auto;
    z-index: 2;
}

.dropdown-content {
    display: none;
    position: absolute;
    left: 20px;
    background-color: #f9f9f908;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}
.dropdown-content_dark {
    display: none;
    position: absolute;
    left: 20px;
    background-color: #4d4d4d08;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    text-decoration: none;
}
.dropdown-contentr {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f910;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}
.dropdown-contentr_dark {
    display: none;
    position: absolute;
    right: 0;
    background-color: #4d4d4d10;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}
.dropdown_item {
    display: flex;
    width: 100%;
    height: 42px;
    padding: 6px;
    justify-content: space-between;
    align-items: center;
    font-family: "Poetsen One", sans-serif;
    text-shadow: 2px 2px 3px white;
    text-decoration: none;
    white-space: nowrap;
    color: black;
}
.dropdown_item_dark {
    display: flex;
    width: 100%;
    height: 42px;
    padding: 6px;
    justify-content: space-between;
    align-items: center;
    font-family: "Poetsen One", sans-serif;
    white-space: nowrap;
    text-decoration: none;
    text-shadow: 2px 2px 3px black;
    color: white;
}
.dropdown_item:hover {
    cursor: pointer;
    background-color: #EEEA;
}
.dropdown_item_dark:hover {
    cursor: pointer;
    background-color: #333A;
}

.dropdown_item img, 
.dropdown_item_dark img {
    width: 50px;
    height: 30px;
    border-radius: 5px;
}

.dropdown:hover .dropdown-content, .dropdown:hover .dropdown-content_dark, .dropdowni:hover .dropdown-contentr, .dropdowni:hover .dropdown-contentr_dark {
    display: block;
}
.footer{
    display: flex;
    width: 100%;
    height: 40px;
    background-color: #EEE;
    margin-top: 15px;
    box-shadow: 0px 0px 10px 0px rgba(46, 46, 46, 0.2);
}
.footer a{
    color: black;
}
.footer_dark{
    display: flex;
    width: 100%;
    height: 40px;
    background-color: #333;
    margin-top: 15px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.5);
    color: white;
}
.footer_dark a{
    color: white;
}
.footerdir, .footeresq{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: calc((100% - 200px) / 2 );
    height: 100%;
    padding-right: 5px;
    font-size: 10px;
}

.footerlat i, .footerlat a{
    height: 33%;
    text-decoration: none;
}
.footerlat i:hover, .footerlat a:hover{
    cursor: pointer;
    text-decoration: underline;
}
.footermeio{
    display: flex;
    width: 200px;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.foto_modal{
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: contain;
}
.foto_modal img{
    width: 100%;
    height: 100%;
}
.description_modal{
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 2rem;
    color: black;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    font-family: 'Courier New', monospace;
    font-weight: 600;
}
.name_modal{
    position: absolute;
    top: 0;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    color: black;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    font-family: "Poetsen One", sans-serif;
}
.anterior{
    position: absolute;
    left: 20px;
    top: 50%;
    width: 50px;
    height: 50px;
    transform: translateY(-25px);
    padding: 3px;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}
.proximo{
    position: absolute;
    right: 20px;
    top: 50%;
    width: 50px;
    height: 50px;
    transform: translateY(-25px);
    padding: 3px;
    text-align: right;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}
.proximo:hover, .anterior:hover{
    color: white;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    cursor: pointer;
}

@media screen and (max-width: 992px) {
    .menu {
        margin: 0 auto 0 0;
        display: flex;
        flex-direction: column;
        left: 0;
        top: 0;
        width: 50%;
        height: 50vh;
        z-index: 3;
        border-right: 1px solid #777;
        border-bottom: 1px solid #777;
        background-image: url("../assets/ceu2.jpg");
        background-position: center;
        background-size: cover;
        transform: translate(-2px, -2px);
    }
    .menu_dark {
        margin: 0 auto 0 0;
        display: flex;
        flex-direction: column;
        left: 0;
        top: 0;
        width: 50%;
        height: 50vh;
        z-index: 3;
        border-right: 1px solid #333;
        border-bottom: 1px solid #333;
        background-image: url("../assets/ceu2dark.jpg");
        background-position: center;
        background-size: cover;
        transform: translate(-2px, -2px);
    }

    .menu1 {
        transform: translateY(35px);
        display: block;
        position: absolute;
        align-items: flex-start;
        width: 100%;
        height: calc(100vh - 70px);
        border: 1px solid;
        z-index: 2;
        background-color: #CCCCCCCC;
        transform: translateY(5px);
    }
    .menu2 {
        display: none;
    }

    .menu_mobil {
        display: block;
        position: absolute;
        left: 10px;
        z-index: 4;
        transform: translateY(-35px);
    }

    .navbar,
    .navbar_dark {
        height: 70px; 
    }
    
    .menu_item, 
    .menu_item_dark, 
    .menu_item_active,
    .menu_item_active_dark{
        margin: auto;
        height: 50px;
        justify-content: flex-start;
    }
    .footeresq{
        display: none;
    }
    .footerdir{
        width: calc(100% - 200px);
    }
    .description_modal{
        position: absolute;
        bottom: 20px;
        width: 100%;
        text-align: center;
        font-size: 1.4rem;
        color: black;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
        font-family: 'Courier New', monospace;
        font-weight: 600;
    }
    .name_modal{
        position: absolute;
        top: 0;
        bottom: 20px;
        width: 100%;
        text-align: center;
        font-size: 2rem;
        color: black;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
        font-family: "Poetsen One", sans-serif;
    }
}