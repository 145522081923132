@import url('https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Sevillana&display=swap'); */

.content {
    display: flex;
    /* position: relative; */
    flex-wrap: wrap;
    height: calc(100vh - 100px);
    background-image: url("/public/assets/background.png");
    background-position: center;
    background-repeat: repeat;
    background-attachment: fixed;
    padding-top: 15px;
    overflow-y: auto;
    width: 100%;
    /* min-height: calc(100vh - 140px); */
    z-index: 0;
}

.content_dark {
    display: flex;
    /* position: relative; */
    flex-wrap: wrap;
    min-height: calc(100vh - 100px);
    height: calc(100vh - 100px);
    background-image: url("/public/assets/backgrounddark.png");
    background-position: center center;
    background-repeat: repeat;
    background-attachment: fixed;
    padding-top: 15px;
    overflow-y: auto;
    width: 100%;
    z-index: 0;
}

.mainsection {
    width: 100%;
    min-height: calc(100vh - 140px);
    /* height: 100vh; */
    margin: 0 auto;

}
.mainsection2 {
    width: 100%;
    min-height: calc(100vh - 140px);
    /* height: 100vh; */
    margin: 0 auto;
}
.content_post{
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 170px);
    width: 100%;
}
.aside {
    width: calc((100% - 850px) / 2);
    margin-bottom: 28px;
    overflow: hidden;
}
.rating_content{
    display: flex;
    height: 40px;
    margin: 0 auto 0 0;
}
.modal_content{
    margin: 0 0 0 auto;
    height: 40px;
}

.post {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;
    /* min-height: calc(100vh - 155px); */
    margin: 5px auto 30px auto;
    text-decoration: none;
    color: black;
    background-color: #EFEFEF;
    padding: 0px 50px 10px 50px;
    box-shadow: 0px 0px 16px 5px rgba(0, 0, 0, 0.2);
}

.post:hover {
    cursor: pointer;
    background-color: #DFDFDF;
}

.post_dark {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;
    margin: 0 auto 30px auto;
    text-decoration: none;
    color: #DDD;
    background-color: #444;
    padding: 0px 50px 10px 50px;
    box-shadow: 0px 0px 16px 5px rgba(0, 0, 0, 0.5);
}

.post_dark:hover {
    cursor: pointer;
    background-color: #424242;
}

.post_coringa_row {
    display: flex;
    flex-wrap: wrap;
}

.image {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
}

.image2 {
    width: 50%;
    transition: transform 0.5s ease-in-out;
}

.image:hover {
    transform: scale(1.05);
}

.image_content {
    width: 100%;
    overflow: hidden;
    position: relative;
    aspect-ratio: 16/8;
}

.image_content2 {
    width: 50%;
    overflow: hidden;
    aspect-ratio: 9/14;
}

.name {
    font-size: 2rem;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 6px;
    color: #3560ed;
    font-family: "Poetsen One", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.short_description {
    width: 100%;
    text-align: justify;
    padding-top: 6px;
    font-weight: 400;
}

.short_description2 {
    width: 50%;
    text-align: justify;
    padding-left: 30px;
    font-weight: 400;
}

.short_description2 p {
    margin: 0;
    padding: 0;
}

.maps_content,
.video_content {
    width: 100%;
    aspect-ratio: 16/8;
}

.maps,
.video {
    width: 100%;
    height: 100%;
}

.lermais {
    padding: 10px 0;
    font-size: 1.4rem;
    font-weight: 700;
    font-family: Baskerville, Baskerville Old Face, Garamond, Times New Roman, serif;
}

.lermais:hover {
    text-decoration: underline;
}

.post_details {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;
    margin: 0 auto 0px auto;
    text-decoration: none;
    color: black;
    background-color: #F8F8F8;
    padding: 0px 50px 10px 50px;
    border: 2px solid white;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
}

.post_details_dark {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;
    margin: 0 auto 0 auto;
    text-decoration: none;
    color: white;
    background-color: #444;
    padding: 0px 50px 10px 50px;
    border: 2px solid #444;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
    border-top: 0.5px solid #666;
}

.post_details:hover {
    cursor: default;
}

.post_name {
    font-size: 1.4rem;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 10px;
    font-family: "Poetsen One", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.post_name_dark {
    font-size: 1.4rem;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 10px;
    font-family: "Poetsen One", sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: #333;
}

.general_description {
    width: 100%;
    text-align: justify;
    padding-top: 6px;
}

.general_description2 {
    width: 50%;
    text-align: justify;
    padding-top: 6px;
    margin-top: 0;
}

.refence {
    width: 100%;
    padding-top: 6px;
}

.address {
    padding-bottom: 8px;
    font-style: italic;
}

.no_registers {
    margin: auto;
    width: 100%;
    text-align: center;
    padding-top: 10vh;
    background-color: transparent;
    font-size: 3rem;
    color: #400FAA;
    font-family: "Poetsen One", sans-serif;
}

.coringa {
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
}

.btn_back_home {
    margin: 0 auto;
    border: 2px solid rgb(0, 128, 0);
    background-color: rgba(0, 128, 0, 0.2);
    font-weight: 600;
    border-radius: 6px;
    padding: 6px;
}

.btn_back_home:hover {
    cursor: pointer;
    background-color: rgba(0, 128, 0, 0.4);
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9, padrão dos vídeos */
    height: 0;
    overflow: hidden;
    margin-bottom: 20px;
}

.video-container iframe,
.video-container object,
.video-container embed,
.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.error_mensagem {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    padding: 25px;
    justify-content: center;
}

.error_mensagem img {
    max-width: 80%;
    max-height: 80%;
}

.privacy_mensagem {
    max-width: 800px;
    margin: auto;
    padding: 10px 10px 30px 10px;
}
/* ************************************* Começa os novo aqui ********************************* */

.np_title1{
    width: 100%;
    padding-top: 12px;
    font-size: 1.8rem;
    font-weight: 800;
    margin: 2px;
    font-family: "Poetsen One", sans-serif;
    color: #3560ed;
}
.np_title2{
    width: 100%;
    padding-top: 10px;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 2px;
    font-family: "Poetsen One", sans-serif;
}
.np_title3{
    width: 100%;
    padding-top: 8px;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 2px;
}
.np_paragrafo{
    width: 100%;
    padding-top: 6px;
    text-align: justify;
    margin: 2px;
}
.np_span{
    width: 100%;
    text-align: justify;
    margin: 2px;
}
.np_divfoto{
    width: 100%;
    margin: 2px;
    margin-top: 10px;
}
.np_foto{
    width: 100%;
    aspect-ratio: 16/8;
    object-fit: cover;
    /* margin: 2px; */
    margin-top: 10px;
    border-radius: 7px;
}
.np_legend{
    width: 100%;
    font-style: italic;
    margin-top: 0;
}
.np_lista{
    width: 100%;
    padding-top: 6px;
    padding-left: 18px;
    text-align: justify;
    margin: 2px;
}
.ed_imgmini, 
.ed_imgmini_dark{
    flex: 1;
}
.ed_imgmini:hover, 
.ed_imgmini_dark:hover{
    z-index: 2;
}
/* aspect-ratio: 16/9; */

.ed_imgmini img, .ed_imgmini_dark img{
    height: 100%;
    width: 100%;
    border-color: transparent;
    object-fit: cover;
    border-radius: 7px;
}
.ed_imgmini img:hover{
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0px 0px 8px 7px rgba(255, 255, 255, 1);
}
.ed_imgmini_dark img:hover{
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0px 0px 8px 7px #444C;
}
.ed_fotos_container{
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    width: 100%;
}
.ed_coringarow{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px 0;
}
.ed_coringacol{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
}
.ed_listafotos{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    gap: 5px;
}
.np_mapa{
    width: 100%;
    margin-top: 15px;
    aspect-ratio: 16/8;
}
.np_mapa iframe {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}
.np_linkinterno a, .np_linkexterno a{
    color: black;
}
.np_linkinterno_dark a, .np_linkexterno_dark a{
    color: white;
}
.pg_not_found{
    margin: auto;
    font-size: 2rem;
    text-align: center;
}
@media screen and (max-width: 992px) {

    .content,
    .content_dark {
        height: calc(100vh - 70px);
    }

    .post,
    .post_dark {
        padding: 0px 8px 8px 8px;
    }

    .short_description2 {
        width: 100%;
        text-align: justify;
        padding-left: 0;
    }

    .image_content2 {
        width: 100%;
    }

    .post_details {
        margin: 0 auto 20px auto;
        padding: 0px 10px 10px 10px;
    }

    .post_details_dark {
        margin: 0 auto 20px auto;
        padding: 0px 10px 10px 10px;
    }
    .aside{
        display: none;
    }
    .mainsection{
        min-height: calc(100vh - 145px);
        overflow-y: auto;
    }
    .ed_listafotos{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        gap: 5px;
    }
}